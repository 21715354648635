import React from 'react'
import Layout from '../components/Layout'

export default function About() {
  return (
      <Layout>
        <div>
            <h1>About Me</h1>
            <p>Software Engineer located in Raleigh, North Carolina</p>
        </div>
    </Layout>
  )
}
